import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Press 4×6\\@80%1RM`}</p>
    <p>{`Dumbbell Pullovers 4×6`}</p>
    <p>{`then,`}</p>
    <p>{`Four 2:00 rounds of:`}</p>
    <p>{`20/15 Calorie Assault Bike`}</p>
    <p>{`Max S2OH reps (115/75)(RX+ 135/95)`}</p>
    <p>{`1:00 Rest Between Sets`}</p>
    <p>{`Score = Total S2OH reps.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      